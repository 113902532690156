import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Accordion from '../../components/ui/Accordion/Accordion';
import Avatar from '../../components/ui/Avatar/Avatar';
import Block from '../../components/ui/Cards/Block';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './about.scss';

interface AboutProps {
    data: {
        file: FluidImage;
        faqYaml: {
            faq: QuestionAnswer[];
        };
    };
}
const About: React.FC<AboutProps> = ({ data }: AboutProps) => {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="About"
                url="about/"
                description="About BLOG NAME"
            />
            <PageBanner className="page-banner-withcircularimage">
                <Fragment>
                    <h1>
                    “If you can explain it simple, you understand it well enough”
                    </h1>
                    <p>
                        Take advantage of what you are learning at school, work, or home!
                    </p>
                </Fragment>
                <Fragment>
                    <Img
                        fluid={data.file.childImageSharp.fluid}
                        alt="WikiExplain"
                        className="logo"
                    ></Img>
                </Fragment>
            </PageBanner>

            <PageSection>
                <Container>
                    <Group size={2}>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <h2>Demystify any topic!</h2>
                            <ul>
                                <li>How to create a Facebook account or TikTok account</li>
                                <li>Reaction to a music video or song</li>
                                <li>How the electoral process works</li>
                                <li>Talk about a YouTube trending video</li>
                                <li>A summary of a book you are reading</li>
                                <li>Explain song lyrics</li>
                                <li>Talk about how the NBA Bubble works</li>
                                <li>An exam you are studying for</li>
                                <li>How to prepare a meal</li>
                                <li>Learning a computer programming language or algorithm</li>
                                <li>How to become an Uber driver</li>
                            </ul>
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <h2>Our Goal</h2>
                            <p>
                                Keep you well informed with the most current trending topics
                            </p>
                        </Block>
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
};

export const aboutQuery = graphql`
    {
        file(relativePath: { eq: "logos/logo.png" }) {
            childImageSharp {
                fluid {
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }

        faqYaml {
            faq {
                question
                answer
            }
        }
    }
`;

export default About;
